import whoIm from '../../images/whoIm.jpg';
import whatIm from '../../images/whatIm.jpg'
import whyIm from '../../images/whyIm.jpg'
import howIm from '../../images/howIm.jpg'
import whenIm from '../../images/whenIm.jpg'
// import hand from '../../images/hand.jpg'

export const who = {
  id: 'who',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Who We Are',
  headline: 'The Founders',
  description: 'Engineers who specialize in the design of expert systems. For the past 10 years we have been building physical and cloud-based architectures that deal in Machine Learning, Data Science, Optimization, Control, and Complex Decision Making. We have been applying this knowledge to an area we have a deep rooted passion for: The Stock Market.',
  buttonLabel: 'More Info',
  imgStart: false,
  img: whoIm,
  alt: 'Who',
  dark: true,
  primary: true,
  darkText: false
};

export const what = {
  id: 'what',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Our Goals',
  headline: 'Take the stress out of trading',
  description:
    'Create a personal assistant tuned to your needs that does all your trading for you. Never have to worry about your trades. Have confidence in your investments knowing that our AI systems are always there improving their decision making and maximizing your return, bull market or bear. ',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: whatIm, // require('../../images/svg-2.svg'),
  alt: 'What',
  dark: false,
  primary: false,
  darkText: true
};

export const why = {
  id: 'why',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Make quantitative assistance more available',
  headline: 'Spend your valuable time away from stock charts',
  description:
    "It takes an incredible amount of knowledge and experience to become a successful Wall Street trader. Time and opportunity that most people don\'t have. Hiring your own personal advisor is often shady since brokers and advisor have their own motives, which typically involve lining their own pockets. We know there is a better way.",
  buttonLabel: 'Why',
  imgStart: false,
  img: whyIm,
  alt: 'Why',
  dark: true,
  primary: true,
  darkText: false
};

export const how = {
  id: 'How',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Training an expert system...',
  headline: '...whose one goal in life is to manage your portfolio',
  description:
    "Large Language Models and General Purpose Transformers are taking AI to the next level by training intelligent systems with all available text data from the internet. This gives AI the ability to converse at levels not yet seen from machines. Have you thought what would happen if you trained an AI on available market data? Focus this intelligence into making safe and precise decisions to meet your investing goals.",
  buttonLabel: 'How',
  imgStart: true,
  img: howIm, // require('../../images/svg-2.svg'),
  alt: 'How',
  dark: false,
  primary: false,
  darkText: true
};
