import React, { useState } from 'react';
import SignUp from '../components/SignUp';
import ScrollToTop from '../components/ScrollToTop';
import Footer from '../components/Footer';
import NavbarHome from '../components/NavbarHome';

function SignupPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ScrollToTop />
      <NavbarHome toggle={toggle} />
      <SignUp />
      <Footer />
    </>
  );
}

export default SignupPage;
