import React, { useState } from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Footer from '../components/Footer';
// import Analysis from '../components/AnalysisForms';
import NavbarHome from '../components/NavbarHome';
import Timeline from '../components/TimelineForms';
import { Account } from '../components/Accounts'

function LearnMore() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Account>
            <ScrollToTop />
            <NavbarHome toggle={toggle} />
            <Timeline />
            <Footer />
        </Account>
    );
}

export default LearnMore;
