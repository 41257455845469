import React from 'react'
import './App.css';
// import { withAuthenticator } from '@aws-amplify/ui-react';
import Home from './pages';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import SignIn from './pages/signin'
import SignUp from './pages/signup'
import ForgotPassword from './pages/forgotpassword';
import LearnMore from './pages/learnmore';
import ContactPage from './pages/contact';
// import { Auth } from 'aws-amplify'



function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path='/' component={Home} exact />
          <Route path='/signin' component={SignIn} exact />
          <Route path='/signup' component={SignUp} exact />
          <Route path='/reset' component={ForgotPassword} exact />
          <Route path='/learnmore' component={LearnMore} exact />
          <Route path='/contact' component={ContactPage} exact />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
