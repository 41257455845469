import React, { useState } from 'react';
import { Button } from '../ButtonElements';
import video from '../../videos/videoH.mp4'
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight
} from './HeroElements';

function HeroSection() {
  const [hover, setHover] = useState(false);
  // const [video, setVideo] = useState({ fileUrl: '' });

  // useEffect(() => {
  //   GetHeroVideo().then(vid => setVideo({ fileUrl: vid }));
  // }, []);


  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg playsInline autoPlay loop muted src={video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        <HeroH1>AI Asset Management</HeroH1>
        <HeroP>
          Expert systems that take the stress out of trading. Real-time access to stock market intel. Tuned to your investment style using Reinforcement Learning.
        </HeroP>
        {/* <HeroBtnWrapper>
          <Button
            to='/laytrader'
            smooth='true'
            duration={500}
            spy='true'
            exact='true'
            offset={-80}
            primary='true'
            dark='true'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Lay Trader {hover ? <ArrowForward /> : <ArrowRight />}
          </Button> */}
        {/* </HeroBtnWrapper> */}
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;
