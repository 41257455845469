import React from 'react';
import 'react-vertical-timeline-component/style.min.css'

import howIm from '../../images/howIm.jpg'
import { Column1, InfoRow, TextWrapper, TopLine, Heading, Subtitle, Column2, ImgWrap, Img, InfoContainer, InfoWrapper} from '../InfoSection/InfoElements';

const Contact = () => {

  return (
      <InfoContainer lightBg={true} id={0}>
      <InfoWrapper>
        <InfoRow imgStart={false}>
          <Column1>
            <TextWrapper>
              <TopLine>Contact Us</TopLine>
              <Heading lightText={false}>Let's have a conversation</Heading>
              <Subtitle darkText={true}>contact@laytrader.io</Subtitle>
              {/* <BtnWrap>
                <Button
                  to='/laytrader'
                  duration={500}
                  exact='true'
                  offset={-80}
                  primary={primary ? 1 : 0}
                  dark={dark ? 1 : 0}
                  dark2={dark2 ? 1 : 0}
                >
                  {buttonLabel}
                </Button>
              </BtnWrap> */}
            </TextWrapper>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={howIm} alt={Contact} />
            </ImgWrap>
          </Column2>
        </InfoRow>
      </InfoWrapper>
    </InfoContainer>
  );
};

export default Contact;
