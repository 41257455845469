import React, { useEffect, useState, useContext } from 'react';
// import Icon1 from '../../images/amd.png';
// import Icon2 from '../../images/nvda.png';
// import Icon3 from '../../images/tsla.png';
// import Icon4 from '../../images/portfolio.png';
// import { MdBusinessCenter } from 'react-icons/md'
import { GiBrain } from 'react-icons/gi'
import { GrAchievement } from 'react-icons/gr'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css'
import {
  TimelineContainer
} from './TimelineElements';
import { AccountContext } from '../Accounts'
import { useHistory } from 'react-router-dom'

const Timeline = () => {

  const [timelineData, setTimelineData] = useState([]);
  const [repos, setRepos] = useState();
  const [repoStatus, setRepoStatus] = useState(false);
  const [timelineStatus, setTimelineStatus] = useState(false);
  const [nothing, setNothing] = useState([]);
  const [status, setStatus] = useState(false);
  const { getSession } = useContext(AccountContext);
    
  const history = useHistory();
  
  useEffect(() => {
      getSession()
          .then(session => {
              // console.log(session.idToken.payload);
              setStatus(true);
          })
          .catch(err => {
              console.log(err);
              history.push('/signin')})
  });

  useEffect(() => {
    fetch('https://api.github.com/orgs/lay-trader/repos',
      {
        headers: new Headers({
          'Authorization': 'Bearer ' + process.env.REACT_APP_GITHUB_FINE_GRAINED,
        })
      })
      .then((res) => res.json())
      .then(
        (result) => {
          setRepos(result);
          setRepoStatus(true);
          console.log(result);
        },
        (error) => {
          console.log(error)
        }
      );
  }, []);

  useEffect(() => {
    repoStatus ? (
      repos.forEach(repo => {
        fetch('https://api.github.com/repos/lay-trader/' + repo.name + '/milestones?state=all',
          {
            headers: new Headers({
              'Authorization': 'Bearer ' + process.env.REACT_APP_GITHUB_FINE_GRAINED,
            })
          })
          .then((res) => res.json())
          .then(
            (result) => {
              console.log(repo.name)
              console.log(timelineData)
              result.length > 0 ? (setTimelineData([...new Set([...timelineData, ...result])])): setNothing(...result);
              console.log(result);
              console.log(nothing)
            },
            (error) => {
              console.log(error)
            }
          );
      })) :
      setTimelineStatus(false);
  }, [repos]);

  useEffect(() => timelineData.length > 0 ? setTimelineStatus(true) : setTimelineStatus(false), [timelineData])

  return (
    status ? (
    timelineStatus ? (
      <TimelineContainer id='timeline'>
        <VerticalTimeline lineColor='black'>
          {
            timelineData.slice().reverse().map((ms, key) => (
              ms.state === "closed" ? (
                <VerticalTimelineElement
                  key={key}
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: '#cc8f2d', color: 'black' }}
                  contentArrowStyle={{ borderRight: '7px solid #cc8f2d' }}
                  date={ms.due_on}
                  iconStyle={{ background: '#cc8f2d', color: '#fff' }}
                  icon={<GrAchievement />}
                >
                  <h3 className="vertical-timeline-element-title">{ms.title}</h3>
                  <h4 className="vertical-timeline-element-subtitle">{ms.state}</h4>
                  <p>
                    {ms.description}
                  </p>
                </VerticalTimelineElement>
              ) :
                <VerticalTimelineElement
                  key={key}
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: 'gray', color: 'black' }}
                  contentArrowStyle={{ borderRight: '7px solid gray' }}
                  date={ms.due_on}
                  iconStyle={{ background: 'gray', color: '#fff' }}
                  icon={<GiBrain />}
                >
                  <h3 className="vertical-timeline-element-title">{ms.title}</h3>
                  <h4 className="vertical-timeline-element-subtitle">{ms.state}</h4>
                  <p>
                    {ms.description}
                  </p>
                </VerticalTimelineElement>
            ))}
        </VerticalTimeline>
      </TimelineContainer>) :
      <TimelineContainer>
      </TimelineContainer>) :
      <></>
  );
};

export default Timeline;
