import whenIm from '../../images/whenIm.jpg'

export const when = {
  id: 'when',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Roadmap',
  headline: 'Initial implementations are already underway',
  description: "We have already begun development of the product with fantastic initial results. Our next steps will be to build infrastructure and processes so that we can provide the application at scale. This will be done using state of the art software development principles that will allow us to establish automation, making the software self sustaining and maintenance minimized. Along the way we will continue to test and perfect the AI so that our first AI to market is adaptable and successful.",
  buttonLabel: 'When',
  imgStart: false,
  img: whenIm,
  alt: 'When',
  dark: true,
  primary: true,
  darkText: false,
  linkString: '/learnmore'
};