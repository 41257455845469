import { React, useState } from 'react';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import UserPool from '../UserPool.js'
import {
  Container,
  FormWrap,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
  Text,
  NavBtnLink
} from '../SignIn/SigninElements.js';
import { Auth } from 'aws-amplify';

const SignUp = () => {

  const [email, setEmail] = useState('');
  const [confirmCode, setConfirmCode] = useState('')
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [message, setMessage] = useState('');
  const [password, setPassword] = useState('');
  const [passwordOne, setPasswordOne] = useState('');
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [verStatus, setVerStatus] = useState(false);

  const onSubmit = event => {
    event.preventDefault();

    const attributes = [
      new CognitoUserAttribute({ Name: 'name', Value: name }),
      new CognitoUserAttribute({ Name: 'email', Value: email}),
    ];
    console.log(attributes)

    if (password !== passwordOne){
      setError('Passwords do not match')
    }
    else{
      UserPool.signUp(email, password, attributes, null, (err, data) => {
        if (err) setError(err.message);//console.error(err.message);
        else {
          setVerStatus(true);
        }
      })
     }
  };

  const onConfirm = event => {
    event.preventDefault();
    try{
      Auth.confirmSignUp(email, confirmCode)   
      setMessage('Successful Sign Up Please Sign In')
      setConfirmStatus(true);
    } catch (err) {
      setError(err.message)
      console.log('error signing up..', err)
    }
  }

  return (
    <>
      <Container>
        <FormWrap>
          <FormContent>
            {verStatus ? (
                <>
                {confirmStatus ? (
                  <Form>
                  <Text>{message}</Text>
                  <NavBtnLink to='/signin'>Sign In</NavBtnLink>
                  </Form>
                  ) :
                  <Form>
                  <FormH1>Check Email For Confirmation Code</FormH1>
                  <FormLabel htmlFor='for'>Confirmation Code</FormLabel>
                  <FormInput value={confirmCode} onChange={event => setConfirmCode(event.target.value)} required />
                  <Text>{error}</Text>
                  <FormButton type='submit' onClick={onConfirm}>Submit</FormButton>
                  </Form>
                }
              </>
            ) :
              <Form action='#'>
                <FormH1>Create Account</FormH1>
                <FormLabel htmlFor='for'>Name</FormLabel>
                <FormInput value={name} onChange={event => setName(event.target.value)} required />
                <FormLabel htmlFor='for'>Email</FormLabel>
                <FormInput type='email' value={email} onChange={event => setEmail(event.target.value.toLowerCase())} required />
                <FormLabel htmlFor='for'>Password</FormLabel>
                <FormInput type='password' value={passwordOne} onChange={event => setPasswordOne(event.target.value)} required />
                <FormLabel htmlFor='for'>Confirm Password</FormLabel>
                <FormInput type='password' value={password} onChange={event => setPassword(event.target.value)} required />
                <FormButton type='submit' onClick={onSubmit}>Request</FormButton>
                <Text>{error}</Text>
                <Text>Already have an account</Text>
                <NavBtnLink to='/signin'>Sign In</NavBtnLink>
              </Form>
            }
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default SignUp;
