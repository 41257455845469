import React, { useState } from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Footer from '../components/Footer';
import NavbarHome from '../components/NavbarHome';
import Contact from '../components/ContactForms';

function ContactPage() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <ScrollToTop />
            <NavbarHome toggle={toggle} />
            <Contact />
            <Footer />
        </>
    );
}

export default ContactPage;

