import { React, useState, useContext, useEffect } from 'react';
import { AccountContext } from '../Accounts'
import { useHistory } from 'react-router-dom'
import {
  Container,
  FormWrap,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
  Text,
  NavBtnLink
} from './SigninElements';


const SignIn = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(true);
  const history = useHistory();

  const { authenticate, getSession, logout } = useContext(AccountContext);

  useEffect(() => {
    getSession()
      .then(session => {
        setStatus(true);
      })
      .catch(err => console.log(err))
  });

  const onSubmit = event => {
    event.preventDefault();

    authenticate(email, password)
      .then(data => {
        console.log('Logged in!', data);
        history.push('/')
      })
      .catch(err => {
        console.error('Failed to login!', err);
        setSubmitStatus(false)
      })
  };

  return (
    <>
      <Container>
        <FormWrap>
          {status ? (
            <FormContent>
              <Form action="#">
                <FormH1>Are you sure you want to sign out?</FormH1>
                <FormButton type='submit' onClick={logout}>Sign Out</FormButton>
              </Form>
            </FormContent>
          ) :
            <FormContent>
              <Form action='#'>
                <FormH1>Sign in to your account</FormH1>
                <FormLabel htmlFor='for'>Email</FormLabel>
                <FormInput type='email' value={email} onChange={event => setEmail(event.target.value)} required />
                <FormLabel htmlFor='for'>Password</FormLabel>
                <FormInput type='password' value={password} onChange={event => setPassword(event.target.value)} required />
                {submitStatus ? (
                  <FormButton type='submit' onClick={onSubmit}>Sign In</FormButton>
                ) :
                  <FormButton type='submit' onClick={onSubmit}>Log in issue, please try again</FormButton>
                }
                <Text></Text>
                <NavBtnLink to='/reset'>Password Reset</NavBtnLink>
                <Text></Text>
                <NavBtnLink to='/signup'>Sign Up</NavBtnLink>
              </Form>
            </FormContent>
          }
        </FormWrap>
      </Container>
    </>
  );
};

export default SignIn;
