import React, { useState } from 'react';
import SignIn from '../components/SignIn';
import ScrollToTop from '../components/ScrollToTop';
import Footer from '../components/Footer';
import NavbarHome from '../components/NavbarHome';
import { Account } from '../components/Accounts';
// TODO: This page is not actually being used since Amplify withAuthenticator is wrapping the entire app. This needs to replace withAuthenticator for custom components
function SigninPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
      setIsOpen(!isOpen);
  };

  return (
    <Account>
    <ScrollToTop />
    <NavbarHome toggle={toggle} />
    <SignIn />
    <Footer />
    </Account>
  );
}

export default SigninPage;
