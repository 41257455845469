import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import {
    who,
    what,
    why,
    how,
} from '../components/InfoSection/Data';
import InfoSectionRouter from '../components/InfoSectionRouter';
import {
    when
} from '../components/InfoSectionRouter/RouterData';
// import CoinTable from '../components/CoinBuildForms/CoinPage';
// import Services from '../components/Services';

function Home() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <HeroSection />
            <InfoSection {...who} />
            <InfoSection {...what} />
            <InfoSection {...why} />
            <InfoSection {...how} />
            <InfoSectionRouter {...when} />
            <Footer />
        </>
    );
}

export default Home;
