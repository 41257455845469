import React, { useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import Pool from "../UserPool";
import {
  Container,
  FormWrap,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
  Text,
  NavBtnLink
} from '../SignIn/SigninElements';

const ForgotPassword = () => {
  const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const getUser = () => {
    return new CognitoUser({
      Username: email.toLowerCase(),
      Pool
    });
  };

  const sendCode = event => {
    event.preventDefault();

    getUser().forgotPassword({
      onSuccess: data => {
        console.log("onSuccess:", data);
      },
      onFailure: err => {
        console.error("onFailure:", err);
      },
      inputVerificationCode: data => {
        console.log("Input code:", data);
        setStage(2);
      }
    });
  };

  const resetPassword = event => {
    event.preventDefault();

    if (password !== confirmPassword) {
      console.error("Passwords are not the same");
      return;
    }

    getUser().confirmPassword(code, password, {
      onSuccess: data => {
        console.log("onSuccess:", data);
        setStage(3);
      },
      onFailure: err => {
        console.error("onFailure:", err);
      }
    });
  };

  return (
    <Container >
      <FormWrap>
        {stage === 1 && (
          <FormContent>
            <Form action='#'>
              <FormH1>Enter your email address</FormH1>
              <FormLabel htmlFor='for'>Email</FormLabel>
              <FormInput type='email' value={email} onChange={event => setEmail(event.target.value)} required />
              <FormButton type='submit' onClick={sendCode}>Send Verification Code</FormButton>
              <Text>Know your account information?</Text>
              <NavBtnLink to='/signin'>Sign In</NavBtnLink>
            </Form>
          </FormContent>
        )
        }
        {
          stage === 2 && (
            <FormContent>
              <Form action='#'>
                <FormLabel htmlFor='for'>Verification Code</FormLabel>
                <FormInput type='password' value={code} onChange={event => setCode(event.target.value)} required />
                <FormLabel htmlFor='for'>Password</FormLabel>
                <FormInput type='password' value={password} onChange={event => setPassword(event.target.value)} required />
                <FormLabel htmlFor='for'>Confirm Password</FormLabel>
                <FormInput type='password' value={confirmPassword} onChange={event => setConfirmPassword(event.target.value)} required />
                <FormButton type='submit' onClick={resetPassword}>Reset Password</FormButton>
              </Form>
            </FormContent>
          )
        }
        {
          stage === 3 && (
            <FormContent>
              <Form action='#'>
                <Text>Successfully Reset Password</Text>
                <NavBtnLink to='/signin'>Return To Sign In</NavBtnLink>
              </Form>
            </FormContent>
          )
        }
      </FormWrap>
    </Container >
  );
};

export default ForgotPassword;